import { createApp } from 'vue'
import App from './App.vue'
import store from "@/store";
import mitt from "mitt";
import './assets/normalize.css';

const app = createApp(App).use(store);
const emitter = mitt();
app.config.globalProperties.$eventHub = emitter;
app.mount('#app')
