<template>
<!--    <button class="neatify-comment-btn" :key="tip.id" @click="correctItem">-->
    <button class="button" :key="tip.id">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "TipButton",
    props: {
        tip: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>
.neatify-comment-btn {
    margin-top: 14px;
    margin-right: 12px;
    padding: 4px 6px;
    background-color: #dddbdb;
    border: 1px solid #B9B9B9;
    border-radius: 4px;
    font-weight: 400;
    cursor: pointer;
    font-family: 'P22UndergroundCYProMedium', Helvetica Neue, Arial, sans-serif;
    font-size: 0.9rem;
    line-height: 1.33;
    text-align: left;
}

@media only screen and (max-width: 767px) {
    .neatify-comment-btn {
        margin-right: 7px;
    }
}
</style>