<template>
    <transition name="fade">
        <div v-if="showButton" class="neatify-clipboard-button-container">
            <button class="neatify-clipboard-button" aria-disabled="false" aria-label="Копировать текст" type="button" @click="copyToClipboard">
                <a href="#"><svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="3" width="1" height="10" rx="0.5" fill="black"/>
                    <rect y="3" width="9" height="1" rx="0.5" fill="black"/>
                    <rect y="12" width="9" height="1" rx="0.5" fill="black"/>
                    <rect x="8" y="3" width="1" height="10" rx="0.5" fill="black"/>
                    <rect x="11" width="1" height="11" rx="0.5" fill="black"/>
                    <rect x="8" y="10" width="4" height="1" rx="0.5" fill="black"/>
                    <rect x="3" width="9" height="1" rx="0.5" fill="black"/>
                    <rect x="3" width="1" height="4" rx="0.5" fill="black"/>
                </svg></a>
            </button>
        </div>
    </transition>
</template>

<script>
export default {
    name: "ClipboardButton",
    data() {
        return {
            isTextCopied: false
        }
    },
    computed: {
        showButton() {
            return this.$store.getters.isTextInserted && !this.isTextCopied
        }
    },
    methods: {
        copyToClipboard() {
            this.$eventHub.emit('clickOnTheClipboardButton');
            this.isTextCopied = true
            setTimeout(() => {
                this.isTextCopied = false
            }, 1000)
        }
    }
}
</script>

<style scoped>
.neatify-clipboard-button-container {
    position: absolute;
    top: 5px;
    right: 3px;
    cursor: pointer;
}
.neatify-clipboard-button {
    border: none;
    background: none;
}
</style>