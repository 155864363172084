<template>
    <div id="neatify-container">
        <div id="neatify-main-window">
            <text-area />
            <clipboard-button />
        </div>
        <div id="neatify-right-sidebar" class="neatify-comments-container">
            <tip-item></tip-item>
        </div>
    </div>
</template>

<script>
import TextArea from "@/components/TextArea";
import ClipboardButton from "@/components/ClipboardButton";
import TipItem from "@/components/TipItem";

export default {
    name: 'App',
    components: {
        TextArea, ClipboardButton, TipItem
    },
    data() {
        return {
            header: 'Проверка оформления&nbsp;текста'
        }
    }
}
</script>

<style scoped>
#neatify-main-window {
    width: 661px;
    padding: 0px 5px;
    background-color: #f0f6f4;
    border-radius: 5px;
    min-height: 200px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
    position: relative;
}

@media only screen and (max-width: 767px) {
    #neatify-main-window {
        /*position: sticky;*/
        top: 0;
        /*z-index: 3;*/
        width: 95vw;
        height: 66.67vh;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;
    }
}

#neatify-right-sidebar {
    width: 275px;
    padding: 0;
    margin-left: 45px;
    position: relative;
}

@media only screen and (max-width: 767px) {
    #neatify-right-sidebar {
        margin: 20px 0 0 0;
    }
}
#neatify-main-container {
    max-width: 981px;
    min-height: 100vh;
    /*margin: 0px auto;*/
    margin-top: 0;
    margin-right: auto;
    margin-left: 28px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 767px) {
    #neatify-main-container {
        width: 100vw;
        padding: 0;
        margin: 0;
    }
}

#neatify-container
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
    padding: 0;
    min-height: calc(100vh - 345px);
}

@media only screen and (max-width: 767px) {
    #neatify-container
    {
        /*position: sticky;*/
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: normal;
        padding: 10px;
        height: 100vh;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;
    }
}
</style>
